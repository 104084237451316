<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    enable-background="new 0 0 26 26"
    xml:space="preserve"
  >
    <image
      id="image0"
      width="26"
      height="26"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAABoCAYAAAAdHLWhAAAT10lEQVR42u1daXhURbp+6/SWfU86
6e50AoRNySCyyCCDsiggIm4XFdyFEUZx90GvjsuMCo6jAsoFEQSXOAoqXuWyPSoXZkB2kEUJhCyd
7nQn6WydpJfT55y6P7J2ujvp/TR38j4PpLuWr6q+91TVd6q+qgb60Y9+9KMf/ehHP/rRj370ox9R
i7iMjJyUDGVJaprySwBxYtcnEiBiV8BXpKWl5QpEpvMYSamVZ8gdzebqbWLXM9S4ZAhKSVdSP5I7
9kuFvwgCB0oleZwg5IEgGRTJAM2jFN+Pbmi4C4A/MkXBpUIQSUlXCoFm/hN4zIUAgbbx0cEKpRQE
ODa6oXGM2A302nCxK+ALMjMzE5wC0xwKWU9S7rcbqdAECFdSEDlFG2GM1VYwxuG4KHZbe+KSIGh3
Tk7NXFbIDLFYGydF/h6H40VKsIRSAVTAkPEWywWx29sdUU/QRVVOAw8kj3MIYasrofStnYL9WQpK
hYTm+Al62MRudwcYsSvQG0pU2T8SIHlIlTGs9aSEPDtdEgsAhGlJsord7u6IWoLK8vNTJISZMqDK
KAMAnkiGdcR98uicsJT5DiMHKMWBpIS/iN3+DkQtQWAdDZTSWgLwACCh/DkAmDdxBK6/YiC+eOKW
kBe5m0gBAATkz2I3vwNROQddUKlGSUGP51cZGQLQjncgqYSB/oPHO9M1tNpx98qtuHncUFz3u4FI
jo9BrEyKGLnUTSbHC2hotePlL/bim8PnvJZ9n8DhTp4FT9lhE5sdxWLrIioJKlerKKXA7wjJkNg5
c0e4af2TIS3nx9PlmL9yq1v4DqcVFMAES7Po+onKIY4CqAb2dyfn7/dOC3k5UwvzYVr/JNYtmuUW
Jzoz7ZAGLyK0KNeodoACMxz81d3D755UGPKyvj10Dos+3OEWvpeRYhLvxPTU9LJdDXUDxNRH1PUg
AjKjZ9hvKxaHpSxjY6vH8CJGBhCCP/LO/OTM7Lli6iPqCKKUtv/XhdSEmM7PrTYWj6zfgfzF7+Gq
5zYEVdbi6aNx9t1FbuGVhACUIocKaKo1bRFTH1E1xJUOyFKCBUBI5xTw4eJZqLPYcPlTa93SV5gt
mPLKp/jplXtcwsc+twGVZotL2O+HqPHxo3OQFKdwCU9PjPVYF0JIx3Mi6op3VBEkZWUPUVBQYD+A
qwFg7EAVRj67DsUrFyM5PsYnOUeWP+Tyvc5ixfqfTuDyp9bCyQk4++7DSE/sY7+PICo2I6JqiKMM
eZYClBDaOazYWA76Dx73mRxPSE+Kw9Kbr0bl2sdR8v4juHHZl2A5vtc8gtDGzi9AvJg6iSqCCKUp
bX+YwwCw5enbMECZEtIyEmLk+PmNByCXSnpNx5I21bQkxC0TUydRRVAHHJReoIT8dvXQXJ/Sc5bm
zn+g/o9L3cl6m7Pjf9hWxHSMb4RZsj8xgVKRXo2ihiCdRtMASkEIQSxDaqeB3Msw7jr5/uAZbD/8
a0jLjo+RAQDed9owTPA89B1ITAh4RzcYRAVBOo2aAjSFtpu3APBuDHOkexpTgwXFA2fiuuffx5Sl
q5C94F0cU16DD/eedRXWbgDe9V87YZ+8CMt+YbEn9gq8uNeIwj96Hq0UUikkAAbQLg56WPrgQfF5
jHRKpHUjOkEVGtXyjs+E0k4F98RPKEBeXh4AgGWdyB+Qh1mzb8Y9r7znlrYlWYONW75FVZURN8yc
jvl334/7HlqIw+fLoTc3uqWPlUuxmvO+DcRSikrWCUqZH4sU8t6tixBDdIIIyNKuL8TrHHLrrbd2
fh4yfATKyyoAAJJTP7iljbfVAwAmTpqMmbPa9o5OnDwJQgj+ts/dc0sulSBX8FBu+8NSz3EuOitS
yCNmgItOUE8QLz0oZs9a3HBVIUrPF+PksUNQKOTYt3sbSNkJdxmsFYS1obSkbVshLS0NUyZfgx92
fI/XZgxxly338jrY/rB4mnw2x8dnR0QfkSikN7TNP+6QZKYg/aV7+szPWVydfaRJiX7X4Z2NO3HN
nqMeFUMBGJxOOHv0bJmCTZ9rQX249SNqD7qoVg/xFucLOZR1hqQecyzuHl0d7liAlwUFC1oioCJx
CZIwdGIw+QWWDUk9rBWmXuOpB4rmAqEpvA+IShAVaLKn8ISbJviY33124G12v+vBt/aeR8wlOVEJ
YgjxaLJKlKl9Z/Zi7bFWm9+rCUIfQ6UgIkOiEmRnOY+nERxny/vOTAha7Q4IgoC6ugaUl+uxa/te
NFpavb5LeQPlRVkk8AlRa8VlvbckYnU4sWA5BJbzGl/uYa6b72Ajoruoew8KBwSh9x4iTRJ1R6FX
iE4QAWo9hTsraoKS+9vp89j8+W5wHAeG6b2ZGZOu8Es2L5MrI6gf8eFpmGNSEpDx1wc8pv9p10FY
HU44Wltw2YjBkEglMFXVoKzMiDRlFqgg4PKB2RhUWOBzHY7d+5rXuK4hjnLzHU5ZJHUTFVverJNT
ymXS6u5htLHlAACP9vaU6eM7P3NODsWnzsPe3IrCYVrk5muQoc70umQUECgFGCyYb3cG56USAKKi
B3mDfevyiBi4jaZalPz54yTisFt6xjULAuo4LmJGQU+IPgf1Bkpp2H2jbS2tkMhlmGg2N09obmHg
ukLQ0iTw/V493mCpa74mOSPJFLwk7yg5eRoFo9q8VglA0dzi4pe1JU5BpYS5RiwdRHUPUi58ozp4
Kd5RcuJ0mxKIZzV8lxBnYAhwh92+TywdRDVB4Yattd3114tBwYCoGML8LGYdo54gSvFjOOSe/tfB
zs8EONUz/oeUxHoJAW5psfm2chsmRPUcBACEYGog+YSmVphf/KhdBmnbMqCALF8JviAHSO/+OkNc
eslRQNYEpAK4X+z2R3UPsn7z5mOB5u0gB+hwyG/77CyvhvDDSUgbuw5yE8Bl39yRlsoyhGBmc+vH
Yusgqt+DbFuXVxBAG0jemiXv+eRfLVAKllI4BQFOgQcrUHBUwIS6hqjQTVQPcZRiJSF4O3AB3qOq
547pNA6G/n7c8KTb//Ocj1Ijiqge4uJufe6dQPPKC1RuYXxyLByaVLSMULtYblKn0OqP7EgiKrpx
X7B9tXwXkeD6QPPXG6thuFjmNX7IVSPTk+e+GnYPnUBwSRDUgQvbVynUrDOrtrxUp8zz7lgv8Dyq
SivQWGv26LfQE+dXbwehcMxzsIGfcQkTLimCAKBq3dLphuLinSFTACegeK2rOAlIwZ1RcvPVJUeQ
fevyagBZhpIy1JuCXwk6//52r3GcnM24rxl1YrY3qo2EnvgmPmY7gCwAUBcMQOHE8UhISQ5YnkTS
+yEuKSs3FynkQlG87Aqx2hzVZjYAVGo0sYfrzWY7L8Q1eriRbMCI4QCAc0dOwOlw+CX7t63HfElG
wJETRQo5CCNMmGfjIro2F7UEbQYkToXcua+2ppOVRrnca/phY0cBFDi9/6BP8gEAuiq/6kQF5kCR
Qg5BQkffY3Uej4Qeom6I+yIubmyRQk6dCjmHHnNkrULee2YCFE4cj+HjRvdZzhs/Bz5/1fPk2Nmc
bPo8cFm49RE1RsI/ZLJRAkN6fSq3qXKx8Z2FPsu0tbSi5ORpj3HnVu8A46cHKgdgVGoqUqRdA08r
z/PDjaawjUSi96BPFYqhRQo57YscAPg1McUv2bEJ8SicOB757fNUB3adb/CbnAqex+TMTBdyACBe
IpHoNGpaqdX4/uT4AVEJKlLIyxhQn9fAAnUOSExJRuHE8UjOTAcAlJ7R+5U/NkaBu7N7P69FBbpO
p1HTCwUFCh/F+gTRCNLl5n78h8zM/Eo+ckc+tUMH4xeSiqlVlT6l5wjwh8xMjElM8rkMhd1m1+Vq
TvmcoQ9EnKBKjeZTnUZNQYV7AWBedjY0CYngfOwfd63ejS2HS/wu91xVAzbs/RWrdvumO5NAMTkj
wJugKS3UadT0Qlqa78x6QcSMhEqNZgUFfTx4SZ7hJICFSNDMEFQzEjQwEtQxDKyEgZ6RwEkIbCCY
adIjztoKlgqQgLg8oQ5KYaQU9yhD59lLgZo8vSFggWEnqCw/P0bCOaPmHmqxoNUbAtJ1WIc4Xa76
hX5y2qDTqKkuV/2uv/nC1oMqNJrHCegKUbUSnajX6g3pviYOWw/qJ8cr0krV6nt9TRyWHqTTqB0A
5EEL+n8MX+ekkPeg8ry8HPST0yd0KtUjvqQLOUEMz/m3RPzvCoa871OyUJZZoVH/ELyUfx/ocnPX
9pUmpHOQtxPb/fCOvuaikPUgnUYdskW1YzYb9lut4AO43jIUqOEitz5I++AgJPsYOpXqDgRJdgnL
YkpNzwPfDS7fzqtViAnB2VOKtkv6NjQ0YLnVBgZAJoCFchm0V47CtTrfFlODBSHkI+L5trOuNKEo
KBRDm1Zv6Py8OjUFs+M9311w1G7HY3X1WJ2WilGxsb3KPGW341OrFQftDlS0+8ddAWA0QzAiT4uJ
L7+KvGnTIE9I6MxjPnsW8jsi82sAvpjaQRNUoVEZCUjQl9t1EHQwWwmV1PeOXeXkUMbzWFpXBx2l
iAGgkkkxQSbDxBEjkDNuHA6sWYMnis8jNiOjT3lFf5iI2Q0NfRccfHt90n1QQ9yZyy6TE0tTSG8e
7IucW0zVaBF4lAgUC5ISMLSgANe98iqOjLwCimR3F6wzn32CaS+84BM5AOA8dw4I4Wq2xzJ4Ic/X
tEH1oFBabTdWV+OUk0O5Ru02md1uMmGgFPiPefMw9e1VACGYq8zC5uq+byNZmpeL5WUVID1uG/nu
zjtQuW8veAcLE6VIkskxMCERM2JDuiHqAWS3Vq+f7mvqgHuQTqN5K5Q3qW1TKqHVG7CntRVT2+ef
cyyLFY11WDBmJObu3NOZtv5CMWaqVd6FUQqn1QqHxYKvm5px0+uvoXjLZjx46gwAgG1pwRe7duOD
AtcLHynvBFj/fOv8AgWnNfhODhBEDwrHO89xuwM3m83QadTgKMUmSy0e2LMfqT0U+dXs6zH+uZeg
udr1wsZfv/wCG55+CkdZJ4wcjyZBwF0J8djU0op6sxlM+/C5ZdYNyCzXYXRcPH6yNGFjczOKHXYc
ygnvPbGB7AlFlePilTEKXClrq9KDtSZsvlgOeQ9/gOKvNqP0xAmY72tbEF5UUtoZt+6Zp/FcVhYk
PUzxeGt5JzkA0Go0InXqtWAfeRRzhg6DXq3CpPg+fg0lSAS6YRcwQVq9gYSjF32rVOLblhYsnDXD
jZyXVRn40UHxdV4+frHb0Tio69fLas+cwabmVhS1WOGkFDPlcuxkWSxMTMRLZ1xvp7//uOtVzk02
Gx5V9TJkBgmt3hDwO2KwZjbRadQhv65wSV09PjYaXcI2jb4cs9H13jOy7CIqa80g7Q7wZz77FJkj
CpFx2XBI5Aqc+HAddi5bhg31DShpbPRaltVcC23BYJRq1KFuBoDAe04Hgh3iaDh6UqWTBc+ykHTz
xR40aw42ffIP3J+eDovA4+VsZSc5ADDibtdrnA+9uRwPpCQjtYdbV+W+fTj/31tx4astYFvafg7g
x+zwmNXBkgOEcLFUp9GwAA3JXWpfNzdDrs3G/H8edgmfk5GGj/IGYvjFEpi6TfqeMD0jHTUU2LF7
F7JHjwEA2OrMeLhgMIbHxeE7mw1rMjOQxjBI6eMYit+g4LQGQ0h0EeLVbM0ugAZ8lrQ7hugNMPUY
mjbPnILjZ0rQSDms1XdtO20aNxb2+jrUcRzKOR6lrANf5OZixsUyHOsmY8Plw2G2tGBxchIqWCdi
GAKlH6sWPmKHVm+4IVTCQr7lfTEnRyuTMBXByrEKArYRBxacLXUJH5SWimfT07DoQtcJxSWqHLzU
w0S2U4rNFTo8Xdd1NnhlehoeyMnp/H6VoQqH1KEzDngpP2BAuak8lPoM+Y7qIKNRF4qxN45hcKCm
yS38mQQZrl32Zuf32jOn8Zm1y7PrpNWGpdW1KCwtx5yNG13ybutx/fIhtQrDDaHZANbqDSTU5ABh
9OrR6g2EgHwUjIyFKamo/sXVJJYoFBh2++2d339+62/Ik0rxTFUVvm5qglmjxn0P3I9YAAU3zelM
12I0wtNBlP3ZSvxsC9x1j4IuD8UD6Q1hfVHN1esfosDCygA38x6tq8OxkaNcwvgem2kz13yAm+Jc
XzL/9/nn8LzE9dk7tnIFRnqYb9IkEvypoQEn+ti68Nw+A0PC/MsBYXeeJ4Cg1RsIJ9Bhvuap43nc
ZKzCi5Pdf3sjIXegy3dZnPsKwO6izzDk9ttcwi5u345VGZ79BQ/l5GC2PyfGCaNuGyHC/7MOET9h
V65UDmBk0lJPcXeaTLgxTgJZbCwWFrvbGWu0WXjw9AWP2wodoJRiTEY69pdcxJfTpqChvAL7BIpT
hOC4KsdrPq3eAF0fL6uCkxuYX11dhggi4mtx7Q0kutzcQaCCyzmS2XESLNZ53kIwHjsCIovrJMda
U4MDb7wO47GjOHSxFD87nTBzPIYxDJ5MSsa/xo7FJJkU6Tk5eDDIOjsYfsRgnelskGICQlScUdVp
VEcAMubOaiO213q+Mmf98AHgnG3VNXMc1jc1Y4hUitdTUjDUy+HiW4wmfJyVhSRJ7yN5x25u9x5E
Qf6Zp9dPEls3UUFQNyjqamtsEpncrV5rtFnY0OzETlUO5H46jhy02jCvvh4PKxRYmtm1s3qryYSj
7UbH35OSMDcpkTpiYmMHl5SEcVPIP0QbQZ2gALE0NT1GKV1BBQGzlRn4XJkTvOBuMHEcdKzzidvq
61dFYsIPBFFLkDfoctVPUmAhoUQFUJ/ugSFAPQUxUkrX5xkMK8Rugz/4P/YHWYhPvojoAAAAAElF
TkSuQmCC"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
